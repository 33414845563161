import { Component, EventEmitter, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-datetime',
  template: `
  <mat-form-field>
  <input matInput [ngxMatDatetimePicker]="picker" [formControl]="formControl" [formlyAttributes]="field">
  
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

  <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
</mat-form-field>
  `,
})
export class FormlyFieldDatetime extends FieldType {
 
  onClick($event) {
    if (this.to.onClick) {
    
      this.to.onClick($event);
    }
  }

}

