import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatbotComponent } from './chatbot.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SafePipe } from './safe.pipe';
@NgModule({
  declarations: [ChatbotComponent, SafePipe],
  imports: [
    CommonModule, BrowserAnimationsModule
  ],
  exports: [
    ChatbotComponent
  ]
})
export class ChatbotModule { }
