import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Form, FormGroup } from "@angular/forms";

@Component({
  selector: "f1-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"]
})
export class SignupComponent implements OnInit {
  constructor() {}

  form = new FormGroup({});
  model = {};
  @Input() fields: any;
  @Input() title: string;
  @Input() submitButtonLable: string;
  @Input() isFacebookLable: string;
  @Input() isLinkedinLable: string;
  @Input() isTwitterLable: string;
  @Input() isGithubLable: string;
  @Input() isFacebook: boolean = false;
  @Input() isLinkedin: boolean = false;
  @Input() isTwitter: boolean = false;
  @Input() isGithub: boolean = false;
  @Output() formSubmit = new EventEmitter();
  @Output() githubOnClick = new EventEmitter();
  @Output() facebookOnClick = new EventEmitter();
  @Output() linkedinOnClick = new EventEmitter();
  @Output() twitterOnClick = new EventEmitter();

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.valid) {
      this.formSubmit.emit(this.model);
    }
  }

  githubClick() {
    this.githubOnClick.emit();
  }

  facebookClick() {
    this.facebookOnClick.emit();
  }

  linkedinClick() {
    this.linkedinOnClick.emit();
  }

  twitterClick() {
    this.twitterOnClick.emit();
  }

  dispFacebook() {
    if (this.isFacebook === true) {
      return true;
    } else {
      return false;
    }
  }

  dispLinkedin() {
    if (this.isLinkedin === true) {
      return true;
    } else {
      return false;
    }
  }

  dispTwitter() {
    if (this.isTwitter === true) {
      return true;
    } else {
      return false;
    }
  }

  dispGithub() {
    if (this.isGithub === true) {
      return true;
    } else {
      return false;
    }
  }
}
