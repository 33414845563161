import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule,FORMLY_CONFIG,FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import {DynamicJsonGenericFormComponent} from './dynamic-json-generic-form.component';
import { PanelWrapperComponent } from './panel-wrapper.component';
import {FormlyWrapperFieldset} from './fieldset.component';
import {FormlyWrapperLabel}from './label.component';
import { FormlyHorizontalWrapper } from './horizontal-wrapper';
import { FileValueAccessor } from './file-value-accessor';
import { FormlyFieldFile } from './file-type.component';
import { RepeatTypeComponent } from './repeat-section.type';
import { RepeatBubbleTypeComponent } from './repeat-bubble-section.type';
// import {FormlyFieldPassword} from './formly-field-password';
import { FormlyFieldButton } from './button-type.component';
// import { TranslateService } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';

import { FormlyMaterialModule } from '@ngx-formly/material';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatStepperModule } from '@angular/material/stepper';
import { FormlyFieldStepper } from './stepper.type';
import { FormlyFieldTabs } from './tabs.type';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormlyMatSliderModule } from '@ngx-formly/material/slider';
import { AnimationWrapperComponent } from './animation-wrapper.component';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyFieldInput } from './formly-field-input';
import {FormlyFieldTemplate}from './formly-template';
import {FormlyFieldCustomInput}from './custom-input.component';
import { from } from 'rxjs';
import {NgxMaskModule} from 'ngx-mask';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '_json');
}
// const appearance: MatFormFieldDefaultOptions = {
//   appearance: 'standard'
// };
export function formlyValidationConfig(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message() {
          return translate.stream('FORM.VALIDATION.REQUIRED');
        },
      },
    ],
  };
}
// export function animationExtension(field: FormlyFieldConfig) {
//   if (field.wrappers && field.wrappers.includes('animation')) {
//     return;
//   }

//   field.wrappers = ['animation', ...(field.wrappers || [])];
// }
@NgModule({
  declarations: [DynamicJsonGenericFormComponent, FormlyHorizontalWrapper, FileValueAccessor,
    FormlyFieldFile,RepeatTypeComponent,FormlyFieldCustomInput,RepeatBubbleTypeComponent,FormlyFieldButton,FormlyFieldTabs,PanelWrapperComponent,FormlyWrapperFieldset,FormlyWrapperLabel,AnimationWrapperComponent,FormlyFieldStepper,FormlyFieldInput,FormlyFieldTemplate],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    MatTabsModule,
    FormlyMatSliderModule,
    BrowserAnimationsModule,MatStepperModule,
    HttpClientModule,
    FormlyMaterialModule,
    MatButtonModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormlyModule.forRoot({
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
         { name: 'animation', component: AnimationWrapperComponent },
         {name:'label',component:FormlyWrapperLabel},
         {name:'fieldset',component:FormlyWrapperFieldset},
         
         
      ],
     
      extensions: [
        // { name: 'animation', extension: { onPopulate: animationExtension } },
      ],
      types: [
        { name: 'file', component: FormlyFieldFile, wrappers: [] },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'repeat_bubble',component:RepeatBubbleTypeComponent},
        // { name: 'password',component:FormlyFieldPassword, wrappers: ['form-field']},
        { name: 'stepper', component: FormlyFieldStepper, wrappers: [] , defaultOptions: {
          templateOptions: {}}},
        {
          name: 'button',
          component: FormlyFieldButton,
          wrappers: ['form-field'],
          defaultOptions: {
            templateOptions: {
              btnType: 'default',
              type: 'button',
            },
          },
        },
        { name: 'tabs', component: FormlyFieldTabs },
        { name: 'template', component: FormlyFieldTemplate },
        { name: 'custom', component: FormlyFieldCustomInput, wrappers: ["form-field"] },
       
      ],
      
    }),
  ],
  providers: [
    { provide: FORMLY_CONFIG, multi: true, useFactory: formlyValidationConfig, deps: [TranslateService] },
   
  ],
  exports:[DynamicJsonGenericFormComponent,FormlyHorizontalWrapper, FileValueAccessor,
    FormlyFieldFile,RepeatTypeComponent,RepeatBubbleTypeComponent,FormlyFieldButton,FormlyFieldCustomInput,FormlyFieldTabs,PanelWrapperComponent,FormlyWrapperFieldset,FormlyWrapperLabel,AnimationWrapperComponent,FormlyFieldStepper,FormlyFieldInput,FormlyFieldTemplate]
})
export class DynamicJsonGenericFormModule { }
