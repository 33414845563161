import { Component,ChangeDetectionStrategy,ViewEncapsulation,ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
 selector: 'formly-field-tile',
 template: `
<div *ngFor="let option of to.options" style="1px solid red;">
  <input class="radio isHidden" type="radio" 
        id="custom_{{option.key}}_{{option.value}}"
        [name]="option.name"
        [formControl]="formControl" 
        [formlyAttributes]="field"
        [value]="option.key"
        [attr.disabled]="option.disabled || formControl.disabled ? true : null" >
        <label for="custom_{{option.key}}_{{option.value}}">
          <div class="tileSet {{option.className}}"   [class.activeState] = "isVisited == option.value"  (click) = "checkVisited(option.value)"  >
            <div class="tile">
            <span> {{ option.value }}</span>
                <div  innerHtml="{{option.description}}"></div>
                <div innerHtml = "{{option.extra}}"></div>
            </div>
          </div>
        
      </label>
      </div>             
 `
 ,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FormlyFieldTile extends FieldType {
 
  public isVisited = false;
  constructor(private cdRef: ChangeDetectorRef)
    {
       super();
    }
    ngOnInit(): void {
     this.onChanges();
    }
    onChanges(): void {
      //console.log(this.model);
      Object.keys(this.model).forEach(e => this.checkVisited(this.model[e]) );
      this.form.valueChanges.subscribe(val => {
        //console.log(this.model);
        Object.keys(this.model).forEach(e => this.checkVisited(this.model[e]) );
    
      });
    }
public checkVisited(val) {
 if(val=="" || val == undefined || val == null){
  this.isVisited = false;
 }else{
   this.isVisited = val;
 }
 this.cdRef.detectChanges();  
  //console.log(this.isVisited);
}
}