import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Form, FormGroup } from "@angular/forms";

@Component({
  selector: "f1-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.css"]
})
export class ForgotpasswordComponent implements OnInit {
  constructor() {}

  form = new FormGroup({});
  model = {};
  @Input() fields: any;
  @Input() title: string;
  @Input() informationDetails: string;
  @Input() submitButtonLable: string;
  @Output() formSubmit = new EventEmitter();

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.valid) {
     // alert(JSON.stringify(this.model, null, 2));
      this.formSubmit.emit(this.model);
    }
  }
}
