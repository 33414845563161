import { Component ,EventEmitter, Output} from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'formly-field-stepper',
  template: `
  
  <mat-horizontal-stepper  >
    <mat-step 
      *ngFor="let step of field.fieldGroup; let index = index; let last = last;"
        [stepControl]="getStepForm(index, step)">
      <ng-template matStepLabel>{{ step.templateOptions.label}}</ng-template>
      <formly-field [field]="step"></formly-field>

      <div>
        <button matStepperPrevious *ngIf="index !== 0" 
          class="btn btn-primary"
          type="button">
          {{step.templateOptions.backButtonText}}
        </button>
        
        <button matStepperNext *ngIf="!last"
          [disabled]="getStepForm(index, step).invalid"
          class="btn btn-primary" type="button">
          {{step.templateOptions.forwardButtonText}}
        </button>
        
        <button *ngIf="last" class="btn btn-primary"
          [disabled]="!form.valid"
          type="submit">
          {{step.templateOptions.submitButtonText}}
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
`,
})
export class FormlyFieldStepper extends FieldType {
  formSteps = {};

  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup.every(f => this.isValid(f));
  }
  defaultOptions = {
    defaultValue: {},
  };

  

  getStepForm(i, field: FormlyFieldConfig) {
    if (!this.formSteps[i]) {
      this.formSteps[i] = new FormGroup(
        field.fieldGroup.reduce<any>((form, f) => {
          form[Array.isArray(f.key) ? f.key.join('.'): f.key] = f.formControl;
          return form;
        }, {})
      );
    }

    return this.formSteps[i];
  }
}
// import { Component, EventEmitter, Output } from '@angular/core';
// import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

// @Component({
//   selector: 'formly-field-stepper',
//   template: `
//   <mat-horizontal-stepper  #stepper>
//     <mat-step
//       *ngFor="let step of field.fieldGroup; let index = index; let last = last;">
//       <ng-template matStepLabel>{{ step.templateOptions.label }}</ng-template>
//       <formly-field [field]="step"></formly-field>

//       <div>
//       <button matStepperPrevious *ngIf="index !== 0"
//         class="btn btn-primary"
//         type="button"
//         (click)="fileUploaded($event)">
//         Back
//       </button>

//       <button matStepperNext *ngIf="!last"
//         class="btn btn-primary" type="button"
//         [disabled]="!isValid(step)"  (click)="fileUploaded($event)">
//         {{step.templateOptions.forwardButtonText}}
//       </button>

//       <button *ngIf="last" class="btn btn-primary"
//         [disabled]="!form.valid"
//         type="submit">
//         Submit
//       </button>
//       </div>
//     </mat-step>
//   </mat-horizontal-stepper>
// `,
// })
// export class FormlyFieldStepper extends FieldType {
//   @Output() itemAdded:EventEmitter<string> = new EventEmitter<string>();
//   @Output() onUploadChange = new EventEmitter();
//   fileUploaded(event) {
//     console.log('this works', event);
//     this.field.templateOptions.onUploadChange.emit(event); // this I want to send further
//   }
//   activedStep=0;
//   isValid(field: FormlyFieldConfig) {
    
//     if (field.key) {
//       return field.formControl.valid;
//     }

//     return field.fieldGroup.every(f => this.isValid(f));
//   }
//   onClickPrev($event) {
//    // this.activedStep = step - 1;
//     if (this.to.onClickPrev) {
//       this.to.onClickPrev($event);
//     }
//     console.log("here-clicked");
//   }

//   onClick($event) {
//     console.log("here-clicked-out");
//     this.itemAdded.emit("testing");
//      if (this.to.onClick) {
//        console.log("here-clicked");
//       this.to.onClick($event);
//     }
//   }
// }
//   // <button matStepperPrevious *ngIf="index !== 0"
//         //   class="btn btn-primary"
//         //   type="button"
//         //   (click)="onClickPrev($event)">
//         //   Back
//         // </button>

//         // <button matStepperNext *ngIf="!last"
//         //   class="btn btn-primary" type="button"
//         //   [disabled]="!isValid(step)"  (itemAdded)='alertItemAdded($event)'>
//         //   {{step.templateOptions.forwardButtonText}}
//         // </button>

//         // <button *ngIf="last" class="btn btn-primary"
//         //   [disabled]="!form.valid"
//         //   type="submit">
//         //   Submit
//         // </button>