import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'formly-field-datepicker-custom',
  template: `
  <mat-form-field style="width:100%" class="no-line" appearance="none">
  <input 
  [id]="id"
  [errorStateMatcher]="errorStateMatcher"
  matInput 
  [matDatepicker]="picker1"  
  [formControl]="formControl" 
  [formlyAttributes]="field"
  [matDatepickerFilter]="to.datepickerOptions.filter"
  [max]="to.datepickerOptions.max"
  [min]="to.datepickerOptions.min"
  [placeholder]="to.placeholder"
  [tabindex]="to.tabindex"
  [readonly]="to.readonly"
  [required]="to.required"
  (click)="picker1.open()"
  (dateInput)="to.datepickerOptions.dateInput(field, $event)"
  (dateChange)="to.datepickerOptions.dateChange(field, $event)"
        > 
        
  <mat-datepicker-toggle matSuffix (click)="detectChanges()" [for]="picker1"  [disabled]="to.disabled" ></mat-datepicker-toggle> 
 
  <mat-datepicker #picker1 [color]="to.color"
  [dateClass]="to.datepickerOptions.dateClass"
  [disabled]="to.datepickerOptions.disabled"
  [opened]="to.datepickerOptions.opened"
  [panelClass]="to.datepickerOptions.panelClass"
  [startAt]="to.datepickerOptions.startAt"
  [startView]="to.datepickerOptions.startView"
  [touchUi]="to.datepickerOptions.touchUi"
  [calendarHeaderComponent]="to.datepickerOptions.calendarHeaderComponent"
  (monthSelected)="to.datepickerOptions.monthSelected(field, $event, picker1)"
  (yearSelected)="to.datepickerOptions.yearSelected(field, $event, picker1)"></mat-datepicker>  
  </mat-form-field>

  
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldDatePickerCustom extends FieldType implements AfterViewInit {
 
  // @ViewChild('datepickerToggle1', { static: true }) datepickerToggle1!: TemplateRef<any>;

  defaultOptions = {
    templateOptions: {
      datepickerOptions: {
        startView: 'month',
        datepickerTogglePosition: 'suffix',
        dateInput: () => {},
        dateChange: () => {},
        monthSelected: () => {},
        yearSelected: () => {},
      },
    },
  };

  detectChanges() {
   // this.options. detectChanges?.(this.field);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    //this.to[this.to.datepickerOptions.datepickerTogglePosition] = this.datepickerToggle1;
  }
    
    }
    
    

