import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-bubble-section',
  template: `
  <div >
  <button class="btn btn-primary" type="button" (click)="add()">{{ to.addText }}</button>
</div>
  <div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
  <div class="col-sm-2 d-flex align-items-center">
    <button class="btn btn-danger" type="button" (click)="remove(i)">Remove</button>
  </div>
  <formly-field class="col" [field]="field"></formly-field>
  
</div>

  `,
})
export class RepeatBubbleTypeComponent extends FieldArrayType {}