import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [CarouselComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule 
  ],
  exports: [
    CarouselComponent
  ]
})
export class CarouselModule { }
