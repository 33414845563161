import { Component, EventEmitter, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-timepicker',
  template: `
 
<ngx-mat-timepicker [formControl]="formControl" [formlyAttributes]="field">
  </ngx-mat-timepicker>
  `,
})
export class FormlyFieldTimepicker extends FieldType {
 
  onClick($event) {
    if (this.to.onClick) {
    
      this.to.onClick($event);
    }
  }

}

