import { Component, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
 selector: 'formly-field-custom-radio',
 template: `
 
  <div *ngFor="let option of to.options">
    <input type="radio" [class.isHidden] = "to.isVisible == true"
    id="custom_{{option.key}}_{{option.value}}"
           [name]="option.name"
           [formControl]="formControl" 
           [formlyAttributes]="field"
           [value]="option.key"
           [attr.disabled]="option.disabled || formControl.disabled ? true : null">
           <label label for="custom_{{option.key}}_{{option.value}}">
           <div  class="{{to.className}}" [class.activeState] ="isVisited == option.value" (click) = "checkVisited(option.value)" >
            <span class="num"> {{ option.value }}</span>
            <div innerHtml="{{option.description}}"></div>
            <div class="extraValueClass">{{option.extra}}</div>
  </div>
  </label>
  </div>
 `,
})
export class FormlyFieldCustomRadio extends FieldType {  
    public isVisited = false; 
    constructor(private cdRef: ChangeDetectorRef)
    {
       super();
    }
    ngOnInit(): void {
      this.onChanges();
    }
    onChanges(): void {
      //console.log(this.model);
      Object.keys(this.model).forEach(e => this.checkVisited(this.model[e]) );
      this.form.valueChanges.subscribe(val => {
        //console.log(this.model);
        Object.keys(this.model).forEach(e => this.checkVisited(this.model[e]) );
    
      });
    }
    // ngAfterViewInit(){
    //   console.log(this.model);
    // ngDoCheck() : void{
    //   Object.keys(this.model).forEach(e => this.checkVisited(this.model[e]) );
    // }
    
    // }  
    // onChanges(): void  {    
    //   Object.keys(this.model).forEach(e => this.checkVisited(this.model[e]) );
    //   //console.log(`key=${e}  value=${this.model[e]}`)
   
    // }
    public checkVisited(val) {
     
      // console.log(" Value is : ", value );
       // reverse the value of property
       this.isVisited = val;
       //console.log("isVisited"+this.isVisited);
       this.cdRef.detectChanges();  
    }
}