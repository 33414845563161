import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { FieldType } from '@ngx-formly/material/form-field';
import { ConcatSource } from 'webpack-sources';

@Component({
  selector: 'formly-field-inline-date',
  template: `
    <mat-card class="calenderWrapper">
      <mat-calendar
        #fulcrumoneCalendar
        [selected]="to.selectedDate"
        [startAt]="to.startAt"
        [minDate]="to.minDate"
        [maxDate]="to.maxDate"
        [dateFilter]="dateFilter"
        [dateClass]="dateClass()"
        (selectedChange)="onSelect($event)"
        (monthSelected)="handleMonthSelected($event)"
        (yearSelected)="handleYearSelected($event)"
        
      ></mat-calendar>
    </mat-card>
    <input
      type="hidden"
      [formControl]="formControl"
      [formlyAttributes]="field"
      value="{{ this.selectedDate }}"
    />
  `,
})
export class FormlyFieldInlineDatePicker extends FieldType {
  @ViewChild('fulcrumoneCalendar') fulcrumoneCalendar;
  renderer: any;
  selectedDate: any; // ["2021-03-25", "2021-03-26"]

  options;
  datas = [];
  constructor(private cdRef: ChangeDetectorRef)
    {
       super();
    }
  ngOnInit(): void {
    this.onChanges();
    this.datas = this.selectedDate;
    //console.log("model get in inline datepicker", this.model)
  }

  ngDoCheck() {
    //this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    // this.dateFilter= (d: Date): boolean => {
    //   return this.model.dateDisabled.findIndex(item => item.toDateString() == d.toDateString()) < 0;
    //  }
    //  if(this.model.IsdisabledChange)
    //  {
    //    console.log("Test FUlcrum one Reset Form");
    //     this.fulcrumoneCalendar.updateTodaysDate();
    //  }
    this.to.viewChildCalendar = this.fulcrumoneCalendar;
  }
  onChanges(): void {
    this.form.valueChanges.subscribe((val) => {
      this.dateFilter = (d: Date): boolean => {
        this.to.viewChildCalendar = this.fulcrumoneCalendar;
        if (this.model && this.model.dateDisabled) {
          //console.log("Date Changed!!");
          return (
            this.model.dateDisabled.findIndex(
              (item) => item.toDateString() == d.toDateString()
            ) < 0
          );
        }
      };
    });
   
  }

  onSelect(event) {
    if (this.to.onClick) {
      this.selectedDate = event;
      this.formControl.setValue(this.selectedDate);
      this.to.onClick(event);
    }

    this.datas = [];
    if (this.to.onClick) {
      this.selectedDate = event;
      this.formControl.setValue(this.selectedDate);
      this.datas.push(this.selectedDate);
      this.to.onClick(event);
    }
   // console.log(this.model);
    this.cdRef.detectChanges(); 
    //this.to.onSelectDate(event, this.fulcrumoneCalendar);
  }

  handleMonthSelected(event) {}
  handleYearSelected(event) {}
  dateFilter = (d: Date): boolean => {
    if (this.model && this.model.dateDisabled) {
      return (
        this.to.dateDisabled.findIndex(
          (item) => item.toDateString() == d.toDateString()
        ) < 0
      );
    }
  };

  changeMonth(navigation) {
    if (this.to.onNavigationChange) {
      
      
      this.to.onNavigationChange(navigation, this.fulcrumoneCalendar);
    }
  }

  dateClass() {
    return (date: any) => {
      let nextPrevObj = {};
      if (date.getDate() == 1) {
        nextPrevObj = {
          date: new Date(date),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
        this.changeMonth({ navigation: nextPrevObj });
      }
      if (this.datas !== undefined) {
        const highlightDate = this.datas
          .map((strDate) => new Date(strDate))
          .some(
            (d) =>
              d.getDate() === date.getDate() &&
              d.getMonth() === date.getMonth() &&
              d.getFullYear() === date.getFullYear()
          );
        return highlightDate ? 'special-date' : '';
      }
    };

    // if (this.datas !== undefined) {
    //   return (date: Date): MatCalendarCellCssClasses => {
    //     const highlightDate = this.datas
    //       .map((strDate) => new Date(strDate))
    //       .some(
    //         (d) =>
    //           d.getDate() === date.getDate() &&
    //           d.getMonth() === date.getMonth() &&
    //           d.getFullYear() === date.getFullYear()
    //       );
    //     return highlightDate ? 'special-date' : '';
    //   };
    // }
  }
}
