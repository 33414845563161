import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "f1-nav-header",
  templateUrl: "./nav-header.component.html",
  styleUrls: ["./nav-header.component.css"],
})
export class NavHeaderComponent implements OnInit {
  constructor() {}
  @Input() position: string = ""; // "fixed-top"; //fixed-bottom
  @Input() textClass: string = "navbar-light";
  @Input() backGroundClass: string = "bg-light";
  @Input() brandText: string = "";
  @Input() isShowBrandIcon: boolean = true; //Fulcrum Digital
  @Input() imageUrl: string = ""; //../../../assets/images/0.jpg
  @Input() headerModules: any;
  @Input() imageWidth: string = "30";
  @Input() imageHeight: string = "30";

  collapsed = true;
  ngOnInit(): void {}
  selectedIndex: number = null;

  setIndex(index: number) {
    this.selectedIndex = index;
    event.preventDefault();
  }
}
