import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "f1-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  @Input() footerBodyContent = [];
  @Input() footerHeaderContent;
  @Input() footerContent: string = "Copyright &copy;";
  @Input() backGroundBodyColor: string = "#ccc";
  @Input() backGroundFooterColor: string = "#ccc";
  @Input() isShowFooterHeader: boolean = false;
  @Input() isShowCopyRightContainer: boolean = true;
  @Input() isShowBodyFooter: boolean = true;
  columnWidth: number = 4;
  constructor() {}

  ngOnInit(): void {
    this.getNumberOfColumnForDisplayLayout();
  }

  getNumberOfColumnForDisplayLayout() {
    if (this.footerBodyContent.length > 0)
      this.columnWidth = 12 / this.footerBodyContent.length;
    console.log(this.columnWidth);
  }
}
