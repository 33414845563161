import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule,FORMLY_CONFIG,FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import {DynamicJsonFormComponent} from './dynamic-json-form.component';
import { PanelWrapperComponent } from './panel-wrapper.component';
import { FormlyHorizontalWrapper } from './horizontal-wrapper';
import { FileValueAccessor } from './file-value-accessor';
import {InlineDateAccessor} from './inline-date-value-accessor';
import { FormlyFieldFile } from './file-type.component';
import { RepeatTypeComponent } from './repeat-section.type';
import { RepeatBubbleTypeComponent } from './repeat-section-bubble.type';
import { FormlyFieldButton } from './button-type.component';
// import { TranslateService } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatStepperModule } from '@angular/material/stepper';
import { FormlyFieldStepper } from './stepper.type';
import { FormlyFieldTabs } from './tabs.type';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormlyMatSliderModule } from '@ngx-formly/material/slider';
import { AnimationWrapperComponent } from './animation-wrapper.component';

import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyFieldInput } from './formly-field-input';
import {FormlyFieldTemplate}from './formly-template';
import {TileWrapperComponent}from './tile-wrapper.component';
import {FormlyFieldDatetime}from './datetime-type.component';
import {FormlyFieldInlineDatePicker} from './inline-datepicker-type.component';
import {FormlyFieldTimepicker}from './time-type.component';
import {FormlyFieldCustomRadio}from './formly-field-custom-radio';
import {FormlyFieldTile} from './formly-field-tile';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatCardModule } from '@angular/material/card';
import {FormlyFieldDatePickerCustom} from './datepicker-custom-type.component';
//import { AutocompleteTypeComponent } from './autocomplete-type.component';
import {
  NgxMatDatetimePickerModule, 
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule 
} from '@angular-material-components/datetime-picker';
import { FormControl, ValidationErrors } from '@angular/forms';
//import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatGridListModule} from '@angular/material/grid-list';
import { from } from 'rxjs';
import { FormlyFieldCustomInput } from './custom-input.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '_json');
}
// const appearance: MatFormFieldDefaultOptions = {
//   appearance: 'standard'
// };
export function formlyValidationConfig(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message() {
          return translate.stream('FORM.VALIDATION.REQUIRED');
        },
      },
    ],
  };
}
export function dateValidator(control: FormControl): ValidationErrors {	
  //return !control.value || /(\d{1,2}\.){3}\d{1,3}/.test(control.value) ? null //: { 'date': true };	
  return { 'dateValidator': true }	
}
// export function animationExtension(field: FormlyFieldConfig) {
//   if (field.wrappers && field.wrappers.includes('animation')) {
//     return;
//   }

//   field.wrappers = ['animation', ...(field.wrappers || [])];
// }
@NgModule({
  declarations: [DynamicJsonFormComponent,
     FormlyHorizontalWrapper, 
     FileValueAccessor,
     InlineDateAccessor,
    FormlyFieldFile,
    RepeatTypeComponent,
    RepeatBubbleTypeComponent,
    FormlyFieldButton,
    FormlyFieldTabs,
    PanelWrapperComponent,
    TileWrapperComponent,
    AnimationWrapperComponent,
    FormlyFieldStepper,
    FormlyFieldDatetime,
    FormlyFieldInlineDatePicker,
    FormlyFieldTimepicker,
    FormlyFieldInput,
    FormlyFieldTemplate,
    FormlyFieldCustomInput,
    FormlyFieldCustomRadio,
    FormlyFieldTile,
    FormlyFieldDatePickerCustom
    ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    MatTabsModule,
    FormlyMatSliderModule,
    BrowserAnimationsModule,MatStepperModule,
    HttpClientModule,
    FormlyMaterialModule,
    MatButtonModule,
    MatNativeDateModule,
    MatCardModule,
    FormlyMatDatepickerModule,
    
    MatDatepickerModule,
    MatInputModule,
    MatGridListModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormlyModule.forRoot({
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
        { name: 'tile', component: TileWrapperComponent },
        
         
      ],
     
      extensions: [
        // { name: 'animation', extension: { onPopulate: animationExtension } },
      ],
      types: [
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'repeat_bubble',component:RepeatBubbleTypeComponent},
        { name: 'datetime', component: FormlyFieldDatetime },
        { name: 'inline_datepicker', component: FormlyFieldInlineDatePicker },
        { name: 'timepicker', component: FormlyFieldTimepicker },
        { name: 'custom_radio', component: FormlyFieldCustomRadio , wrappers: ['form-field'],defaultOptions:{templateOptions:{ hideFieldUnderline: true}}},
        {name:'tile',component:FormlyFieldTile, wrappers: ['form-field'],defaultOptions:{templateOptions:{ hideFieldUnderline: true}}},
        { name: 'stepper', component: FormlyFieldStepper, wrappers: [] , defaultOptions: {
          templateOptions: {}}},
        {
          name: 'button',
          component: FormlyFieldButton,
          wrappers: ['form-field'],
          defaultOptions: {
            templateOptions: {
              btnType: 'default',
              type: 'button',
            },
          },
        },
        { name: 'tabs', component: FormlyFieldTabs },
        { name: 'template', component: FormlyFieldTemplate },
       
        { name: 'custom', component: FormlyFieldCustomInput, wrappers: ['form-field'] },
        { name: 'datepicker-custom', component: FormlyFieldDatePickerCustom ,defaultOptions: {
         
          templateOptions: {
            datepickerOptions: {},
            hideFieldUnderline: true,
          },
        }, wrappers: ['form-field']},
      
       
      ],
      
    }),
  ],
  providers: [
    { provide: FORMLY_CONFIG, multi: true, useFactory: formlyValidationConfig, deps: [TranslateService] },
    // {
    //   provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    //   useValue: appearance
    // }
  ],
  exports:[DynamicJsonFormComponent,
    FormlyHorizontalWrapper, 
    FileValueAccessor,
    InlineDateAccessor,
    FormlyFieldFile,
    RepeatTypeComponent,
    RepeatBubbleTypeComponent,
    FormlyFieldButton,
    FormlyFieldTabs,
    PanelWrapperComponent,
    TileWrapperComponent,
    AnimationWrapperComponent,
    FormlyFieldStepper,
    FormlyFieldDatetime,
    FormlyFieldInlineDatePicker,
    FormlyFieldTimepicker,
    FormlyFieldCustomInput,
    FormlyFieldInput,
    FormlyFieldTemplate,
    FormlyFieldCustomRadio,
    FormlyFieldTile,
    FormlyFieldDatePickerCustom
    ]
})
export class DynamicJsonFormModule { }
