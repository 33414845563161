import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Form, FormGroup } from "@angular/forms";

@Component({
  selector: "f1-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"],
})
export class SigninComponent implements OnInit {
  constructor() {}
  form = new FormGroup({});
  model = {};
  @Input() fields: any;
  @Input() title: string;
  @Input() submitButtonLable: string;
  @Input() displayForgotPass: boolean = false;
  @Input() displayRegister:boolean = false;
  @Output() formSubmit = new EventEmitter();
  @Output() forgotPassword = new EventEmitter();
  @Output() rememberMeChange = new EventEmitter();
  @Output() register = new EventEmitter();
  @Input('RegisterHereText') RegisterHereText : string = "Rigster Here";
  @Input('forgetPasswordText') forgetPasswordText : string = " Forget Password";

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.valid) {
     // alert(JSON.stringify(this.model, null, 2));
      this.formSubmit.emit(this.model);
    }
  }

  isdisplayForgotPass() {
    if (this.displayForgotPass === true) {
      return true;
    } else {
      return false;
    }
  }

  isdisplayRegister(){
    if (this.displayRegister === true) {
      return true;
    } else {
      return false;
    }
  }

  forgotPass() {
    this.forgotPassword.emit();
  }
  registerHere() {
    this.register.emit();
  }

  rememberMe($event) {
    if (
      $event.checkbox &&
      $event.input != undefined &&
      $event.password != undefined
    ) {
      this.rememberMeChange.emit(this.model);
    }
  }
}
