import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'f1-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']

})
export class CarouselComponent implements OnInit {
  @Input() sliderArray: object[];
  @Input() intervals: number;
  @Input() slideDirection = 'right';
  @Input() showArrows: boolean = true;
  @Input() showIndicators: boolean = false;
  @Input() showCaptions: boolean = false;
  @Input() width: string = '800';
  @Input() height: string = '400';

  selectedIndex = 0;
  constructor() {
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.intervals && this.intervals != 0) {
      setInterval(() => {
        if (this.slideDirection == 'left') {
          this.prevSlide();
        } else {
          this.nextSlide();
        }
      }, this.intervals);
    }
  }
  selected(x) {
    this.selectedIndex = x;
  }
  nextSlide() {
    let slideNumber = this.selectedIndex + 1;
    if (slideNumber < this.sliderArray.length) {
      this.selectedIndex++;
    } else if (slideNumber === this.sliderArray.length) {
      this.selectedIndex = 0;
    }
  }
  prevSlide() {
    let slideNumber = this.selectedIndex - 1;
    if (slideNumber < 0) {
      this.selectedIndex = this.sliderArray.length - 1;
    } else if (slideNumber > 0) {
      this.selectedIndex = slideNumber;
    } else {
      this.selectedIndex = 0;
    }
  }
}
