import { Component, OnInit,Input,Output, EventEmitter,OnDestroy } from '@angular/core';

import { FormArray, FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';


import { Subject } from 'rxjs';
import { takeUntil, startWith, tap, filter, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'f1-dynamic-generic-form',
  templateUrl: './dynamic-json-form.component.html',
  styleUrls: ['./dynamic-json-form.component.css']
})
export class DynamicJsonGenericFormComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
    constructor(public translate: TranslateService) {
      translate.addLangs(['en', 'fr']);
      translate.setDefaultLang('en');
  
      const browserLang = translate.getBrowserLang();
  
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
      this.model.lang = translate.currentLang;
    }
    form = new FormGroup({});
    
    formSteps = {};
    @Output() onSubmitButtonclick: EventEmitter<any> = new EventEmitter();
    @Output() onExtraButtonClick:EventEmitter<any> = new EventEmitter();
    @Input("submitButtonText") submitButtonText:string = 'Submit';
    @Input("isShowSubmitButton") isShowSubmitButton:boolean = true;
    @Input("isShowExtraButton") isShowExtraButton:boolean = false;
   @Input("ExtraButtonText") ExtraButtonText = "";
    @Input("fields") fields: FormlyFieldConfig[] = [];
    @Input("model") model: any = {};
    @Input("options") options: FormlyFormOptions = { formState: {
      mainModel: this.model,
    },};
    @Input("resetButtonText") resetButtonText : string = "Reset";
    @Input("isShowResetButton") isShowResetButton : boolean = true;
    @Input ("isShowUpdateIntialValuesButton") isShowUpdateIntialValuesButton : boolean = true;
    @Input ("updateInitalButtonText") updateInitalButtonText : string = "Update";
  @Input("forwardButtonText") forwardButtonText:string ="Next";
  @Input("backButtonText") backButtonText = "Back";
  @Input("isLinear") isLinear = true;
  @Input("formTitle") formTitle = "";
  @Input("formDescription") formDescription = "";
  @Input("isShowFormDescription") isShowformDescription = false;
  @Input("isShowFormTitle") isShowFormTitle =false;
  @Input("resetform") resetform: boolean =false;
  @Output() onFormChange: EventEmitter<any> = new EventEmitter();
    ngOnInit(): void {
    this.onChanges();
    }  
    ngAfterViewInit() {
      if(this.resetform)
      {   
        this.options.resetModel();  
      }
    }
    onChanges(): void {
      this.form.valueChanges.subscribe(val => {
      let data = {model:this.model,form:this.form,options:this.options};
      this.onFormChange.emit(data);
      });
    }
 
  submit() {
    console.log(this.form);
    let data = {model:this.model,form:this.form};
    this.onSubmitButtonclick.emit(data);
  }
  extraButtonClick(){
    let data = {model:this.model,form:this.form};
    this.onExtraButtonClick.emit(data);
  }
  onClick(){
   // console.log("am i here");
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
