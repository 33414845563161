import { Component, OnInit,Input,Output, EventEmitter,OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectionStrategy } from "@angular/core";  

import { Subject } from 'rxjs';
import { takeUntil, startWith, tap, filter, distinctUntilChanged } from 'rxjs/operators';
export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
  backButtonText:string;
  nextButtonText:string;
 
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'f1-dynamic-json-form',
  templateUrl: './dynamic-json-form.component.html',
  styleUrls: ['./dynamic-json-form.component.css']
})
export class DynamicJsonFormComponent implements OnInit, OnDestroy {

  onDestroy$ = new Subject<void>();
  renderer: any;
    constructor(public translate: TranslateService) {
      // translate.addLangs(['en', 'fr']);
      // translate.setDefaultLang('en');
     
      // const browserLang = translate.getBrowserLang();
  
      // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
      // this.model.lang = translate.currentLang;
     
      
    }
 
 

  formSteps = {};
  @Input("fields")  fields: StepType[] = [];
  @Output() onButtonclick: EventEmitter<any> = new EventEmitter();
  @Output() onPrevStep: EventEmitter<any> = new EventEmitter();
  @Output() onNextStep: EventEmitter<any> = new EventEmitter();
  @Output() onFormChange: EventEmitter<any> = new EventEmitter();
  @Input("submitButtonText") submitButtonText:string = 'Submit';
  @Input("isShowSubmitButton") isShowSubmitButton:boolean = true;
  @Input("resetButtonText") resetButtonText : string = "Reset";
  @Input("isShowResetButton") isShowResetButton : boolean = false;
  @Input ("isShowUpdateIntialValuesButton") isShowUpdateIntialValuesButton : boolean = false;
  @Input ("updateInitalButtonText") updateInitalButtonText : string = "";
  @Input("forwardButtonText") forwardButtonText:string ="Next";
  @Input("backButtonText") backButtonText = "Back";
  @Input("isLinear") isLinear = true;
  @Input("totalNumberOfSteps") totalNumberOfSteps:number = 1;
  @Input("labelPositionParam") labelPositionParam = 'end';
  @Input("textForSteps") textForSteps:string = 'Steps';
  @Input("textorsymbolforseperator") textorsymbolforseperator:string = '/';
  @Input("isShowStepsText") isShowStepsText:boolean=false;
  @Input("classForShowText") classForShowText:string="";
  @Input("formOptions") formOptions ="";
  @Input("autocompleteValue") autocompleteValue = "on";
  @Input("model")model: any = {};
  @Input("resetform") resetform:boolean =false;
  form;
  options;
 
ngOnInit(): void {
  
  this.form = new FormArray(this.fields.map(() => new FormGroup({})));
  this.options = this.fields.map(() => <FormlyFormOptions> {formState: {
  mainModel: this.model,
  extraOptions:this.formOptions

}});


this.onChanges();
}  
ngAfterViewInit() {
  if(this.resetform)
  {   
    this.options.forEach(option => option.resetModel());  
  
  }
  
}
onChanges(): void {
  
  this.form.valueChanges.subscribe(val => {
    
  let data = {model:this.model,form:this.form,options:this.options};
  this.onFormChange.emit(data);
  });
}
selectedIndex=1;
currentStep(selectedIndex){
  this.selectedIndex = selectedIndex+1;
}
selectionChange(e){
 
  this.currentStep(e.selectedIndex);
  
}
  submit() {
   let data = {model:this.model,form:this.form};
    this.onButtonclick.emit(data);
  }
 

  prevStep(e) {
    this.selectedIndex = e;
    let data = {model:this.model,step:this.selectedIndex};
    this.onPrevStep.emit(data);
  }

  nextStep(e) {
   
    this.selectedIndex = e+2;
    let data = {model:this.model,step:this.selectedIndex};
    this.onNextStep.emit(data);
    
  }

  

  


  onClick(){
    
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


}
