import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavHeaderComponent } from "./nav-header.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
@NgModule({
  declarations: [NavHeaderComponent],
  imports: [CommonModule, NgbModule],
  exports: [NavHeaderComponent],
})
export class NavHeaderModule {}
