import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  AfterViewChecked,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChatbotService } from './chatbot.service';
import { element } from 'protractor';

/**
 * Declares the WebChat property on the window object.
 */
declare global {
  interface Window {
    WebChat: any;
  }
}
window.WebChat = window.WebChat || {};
@Component({
  selector: 'f1-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(1, .3, .9)' }),
        animate(400),
      ]),
      transition('* => void', [
        animate(200, style({ transform: 'scale3d(.0, .0, .0)' })),
      ]),
    ]),
  ],
})
export class ChatbotComponent implements OnInit, AfterViewChecked {
  @ViewChild('botWindow') botWindowElement: ElementRef;

  @Input() botType: string;
  @Input() botSource: string;
  @Input() secretKey: string;
  @Input() showAnimations: boolean = false;
  @Input() themeColor: string = '#3599cc';
  @Input() bubbleBackground: string;
  @Input() bubbleFromUserBackground: string;
  @Input() botAvatarImage: string;
  @Input() botAvatarBackgroundColor: string;
  @Input() botAvatarInitials: string;
  @Input() userAvatarImage: string;
  @Input() userAvatarBackgroundColor: string;
  @Input() userAvatarInitials: string;
  @Input() width: string = '400';
  @Input() height: string = '600';
  @Input() side: string = 'right';
  @Input() botName: string = 'F1-BOT';
  @Input() isShowOnlineIco: boolean = true;
  @Input() onlineIconURL: string; //= '../../../assets/img/greenDot.png';
  @Input() chatBubbleBgColor: string = this.themeColor;
  @Input() chatButtonClass: string = 'ms-Icon ms-Icon--MessageFill';
  @Input() chatButtonIconColor: string = this.themeColor;
  @Input() chatButtonIconOnHoverColor: string = this.themeColor;
  @Input() borderRadius: string;
  @Input() borderColor: string = this.themeColor;
  @Input() isSwitchVisible: boolean = false;
  @Input() isMiniVisible: boolean = false;
  @Input() isCancelVisible: boolean = true;
  @Input() bubbleFromUserTextColor: string;
  @Input() labelForRightIcon: string;
  @Input() bubbleBorderColor: string;
  @Input() bubbleBorderRadius: string;
  @Input() bubbleBorderStyle: string;
  @Input() bubbleTextColor: string;
  @Input() bubbleBorderWidth: string;
  @Input() showTitleBubblewithTextPanel: string;
  @Input() showTextForPanel: string;
  @Input() showbubblewithTextPanel: boolean = false;
  @Input() displaybubble: string;

  showButton: boolean;
  directLine;
  themeColorDark;
  hover;
  container: HTMLElement;
  constructor(private chatbotService: ChatbotService) {
    this.chatbotService.load('chatbotjs');
  }
  ngOnInit(): void {
    this.showButton = true;
    this.themeColorDark = this.LightenDarkenColor(this.themeColor, -20);
    this.container = document.getElementById('msgContainer');
    // this.container.scrollTop = this.container.scrollHeight;
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      // this.botWindowElement.nativeElement.S = this.botWindowElement.nativeElement.scrollHeight;
      window.scrollBy(4000, 0);
    } catch (err) {}
  }
  LightenDarkenColor(col, amt) {
    let usePound = false;
    if (col[0] == '#') {
      col = col.slice(1);
      usePound = true;
    }
    let num = parseInt(col, 16);
    let r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    let g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }
  maximizeChatWIndow() {
    this.showButton = false;
    this.showbubblewithTextPanel = false;
    if (this.botType === 'directline') {
      setTimeout(() => {
        this.directLine = window.WebChat.createDirectLine({
          secret: this.botSource,
          webSocket: false,
        });
        window.WebChat.renderWebChat(
          {
            directLine: this.directLine,
            userID: 'USER_ID',
            styleOptions: {
              bubbleBackground: this.bubbleBackground,
              bubbleBorderColor: this.bubbleBorderColor,
              bubbleBorderRadius: this.bubbleBorderRadius,
              bubbleBorderStyle: this.bubbleBorderStyle,
              bubbleTextColor: this.bubbleTextColor,
              bubbleBorderWidth: this.bubbleBorderWidth,
              bubbleFromUserBackground: this.bubbleFromUserBackground,
              avatarSize: 40,
              botAvatarBackgroundColor: this.botAvatarBackgroundColor, // defaults to accent color
              botAvatarImage: this.botAvatarImage, //'url(../../src/assets/img/svg/myAvatar.svg);',
              botAvatarInitials: this.botAvatarInitials, //'',
              userAvatarBackgroundColor: this.userAvatarBackgroundColor, // defaults to accent color
              userAvatarImage: this.userAvatarImage, //'url(../../src/assets/img/svg/myAvatar.svg)',
              userAvatarInitials: this.userAvatarInitials,
              hideScrollToEndButton: false, //'',
              bubbleFromUserTextColor: this.bubbleFromUserTextColor,
            },
            // styleSet,
          },
          this.botWindowElement.nativeElement
        );
        this.directLine
          .postActivity({
            from: { id: 'USER_ID', name: 'USER_NAME' },
            name: 'requestWelcomeDialog',
            type: 'event',
            value: 'token',
          })
          .subscribe(
            (id) => console.log(`Posted activity, assigned ID ${id}`),
            (error) => console.log(`Error posting activity ${error}`)
          );
      }, 1);
    }
  }
  minimizeChatWindow() {
    this.showButton = true;
    this.side = 'right';
    this.hover = false;
    this.showbubblewithTextPanel = true;
  }
  resetChatWindow() {
    this.coreResetChatWindow('webchat__stacked_indented_content');
    this.coreResetChatWindow('webchat__bubble__content');
    this.coreResetChatWindow('webchat__suggested-action');
    this.coreResetChatWindow('webchat__row');
    this.coreResetChatWindow('webchat__stacked-layout__status');

    this.maximizeChatWIndow();
  }
  handleSwitchButtonClick() {
    this.side = this.side === 'left' ? 'right' : 'left';
  }
  coreResetChatWindow(cName) {
    Array.from(document.getElementsByClassName(cName)).forEach((element) =>
      element.remove()
    );
  }

  showbubble() {
    console.log(this.displaybubble);
    return this.displaybubble;
  }
}
